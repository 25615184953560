import React from "react";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import Link from "../components/Global/Link";

// Markup
const Gifts = () => {
  const openGraph = {
    type: "website",
    title: "Gifts | CP4RP",
    socialTitle: "All the details for Claire & Richy's big day",
    description: "20th May 2023 | Left Bank, Leeds",
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <Hero title="Gifts" colour="pink" />
      <section className="py-5 brand-background-black">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-12 brand-text-white text-center">
              <h2 className="brand-font brand-responsive mb-4">
                Your presence really is our present
              </h2>
              <p>
                That bold statement said... if you'd like to contribute to our
                honeymoon fund then we'd be really thankful. We'll have a box
                for cards on the day.
              </p>

              <p>Champagne is always gratefully received.</p>

              <p>
                Importantly,{" "}
                <Link
                  to="https://www.mind.org.uk/donate/"
                  className="brand-link-pink"
                >
                  Mind
                </Link>{" "}
                is a charity that is extremely close to our hearts. If you'd
                like to support the cause with a dontation, that's a gift we'd
                be truly grateful for as well.
              </p>

              <p>
                No pressure at all - we really do just want to raise a toast
                with you on the day and see you on the{" "}
                <Link to="/lineup/" className="brand-link-pink">
                  dancefloor
                </Link>
                !
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Gifts;
